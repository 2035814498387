body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #808080;
}

.kmxlogoSignin{
width: auto;
height: 25px;
margin-left: 80%;
float: right;
margin-bottom: .5%;
}

.code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.appBar{
  background-color: white;
  box-shadow: .5px 1px #888888;
}

.headerLabel{
  color:#053361;
  font-family: Verdana, Tahoma, sans-serif;
  font-weight: 900;
  font-size: large;
}

.kmxlogoSignin{
 height:20%;
 width: 45%
}
.logoSignin{
  margin-top: -7%;
  width: 65%;
}

.signinCard{
  display: block;
  margin-left: 15%;
  margin-top: 1%;
  padding: .5%;
  width: 25%;
  height:auto;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: white;
  border-radius: 5%;
}


.loginButton{
  background-color: yellow;
  border-radius: 60px;
  width: 60%;
  margin-bottom: 5%;
}

.menuHeader{
  width: 100%;
  background-color: #053361;
  box-shadow: 1px 2px #cfcaca;
}

.menuLinks{
  display: inline-block;
  color: white;
  padding-left: 3.5%;
  padding-top: .5%;
  padding-bottom: .5%;
}
.menuLinkReact{
color: white;
}

ul{
  margin-top: 0px;
}
li{
  color: white;
}

.drawer{
  background-image: linear-gradient(#42424a, #191919);
}
.inline{
  display: inline-block;
}
.inline2{
  display: inline-block;
}

.topIcons{
  width: 22px;
  height: 22px;
  margin-left: 3%;
  border-radius: 15%;
  margin-top: 1.8%;
  float: left;
}

.sliderDiv{
  width: 80%;
  padding: .1%
}

.editButton{
  float: right; 
  color: white;
  width: 18%;
  height: 35px;
  margin-right: 5%;
  margin-top: 30%;
}

.confirmationButton{
  margin-left: 300px;
}

.accordionStyle{
  width: 70%;
  margin: auto;
}

.infoName{
  font-family: monospace;
}

.center{
  margin:auto;
}
.centerEverything {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

.drawerLogo{
width: 10%;
position:fixed;
bottom:0px;
}

a:visited {
  color: black;
}

.drawerBottomLogo{
  width: 50%;
  margin-left: 15%;
  height: 2.5%;
}

.logOutText{
position:fixed;
bottom:0px;
margin-left: 1%;
color: 'white';
}

.logOutIcon{
  position:fixed;
  bottom:0px;
  margin-right: 2%
  }

.landingLogo{
  border-radius: 1055px;
  box-shadow: 10px 10px 10px rgb(187, 188, 189);
  display: block;
  margin-left: auto;
  margin-right: auto;
  align-items: left;
  width: 100%;
  height: 100%;
  margin-top: 1%;
  margin-left: -30%
}

.signInLogo{
  border-radius: 1055px;
  box-shadow: 10px 10px 10px rgb(187, 188, 189);
  margin-bottom: 5%;
  margin-top: -5%;
  width: 70%

}

/* .landingText{
font-size: 2vw
} */

.landingHr{
  width: 850%
}

.landingPage {


}

.landingDiv{
  max-width: fit-content;
  width: 50%;
  margin: auto
}

.landingPara{
  text-shadow: .7px .7px #F5F5F5;
  font-family: 'Roboto';
}

.drawerKmxLogo{ 
  width:10%;
  position:fixed;
bottom:0px;
}

.uiHeaderFont{
  /* color: #000e77; */
  text-shadow: .35px .35px 1px #F5F5F5;
  font-family: 'Roboto'

}

.uiSubHeaderFont{
   text-shadow: .5px .5px 1px #F5F5F5;
   font-family: 'Roboto'
  
}